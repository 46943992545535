





























































import { Component, Vue } from 'vue-property-decorator';
import { appName } from '@/env';
import { dispatchPasswordRecovery } from '@/store/main/actions';

@Component
export default class Login extends Vue {
  public valid = true;
  public username = '';
  public appName = appName;

  public cancel() {
    this.$router.back();
  }
  public get theme() {
    return this.$vuetify.theme.dark ? 'dark' : 'light';
  }
  get ifUsernameExist() {
    if (this.username.length === 0) {
      return true;
    } else {
      return false;
    }
  }
  public submit() {
    dispatchPasswordRecovery(this.$store, { username: this.username });
  }
}
